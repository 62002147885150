import { Directive, OnInit, ViewContainerRef } from '@angular/core';
import { DialogService } from './dialog.service';

@Directive({
  selector: '[wlxDialogHost]',
})
export class DialogDirective implements OnInit {
  constructor(
    public viewContainerRef: ViewContainerRef,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.dialogService.viewContainerRef = this.viewContainerRef;
  }
}
